



























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class PlayVideoPopup extends Vue {
  @Prop() popupId!: string
  @Prop() video!: any
}
