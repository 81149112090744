var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.brandTutorialVideos, function(video) {
        return _c("div", { key: video.id, staticClass: "col-sm-6 item-list" }, [
          _c(
            "a",
            {
              staticStyle: { color: "black" },
              attrs: {
                "data-target": "#play-video-popup",
                "data-toggle": "modal"
              },
              on: {
                click: function($event) {
                  return _vm.setActiveVideo(video)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "green-border-box outer-box",
                  staticStyle: { "min-height": "150px", cursor: "pointer" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "inner-box",
                      staticStyle: { "margin-top": "20px" }
                    },
                    [
                      _c("div", { staticClass: "img-responsive col-sm-3" }, [
                        _vm._m(0, true),
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            src:
                              "https://img.youtube.com/vi/" +
                              video.videoId +
                              "/0.jpg"
                          }
                        })
                      ]),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("h3", [_vm._v(_vm._s(video.title))]),
                        _c("p", [_vm._v(_vm._s(video.description))])
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ])
      }),
      _c("PlayVideoPopup", {
        attrs: { video: _vm.activeVideo, popupId: "play-video-popup" }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticStyle: {
          position: "absolute",
          top: "36%",
          left: "36%",
          background: "transparent",
          border: "none"
        }
      },
      [
        _c("img", {
          staticStyle: { width: "120%", height: "auto" },
          attrs: { src: "/img/Dashboard/videoexplainingActive_icon.png" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }