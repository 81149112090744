






















import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import PlayVideoPopup from '@/components/Brand/PlayVideoPopup.vue'

@Component({
  components: {
    PlayVideoPopup
  }
})
export default class TutorialVideos extends Vue {
  public query = ''
  public limit = 500
  public offset = 0
  public actionCountry: any = null
  public brandTutorialVideos: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public activeVideo: {id: string, title: string, description: string, videoUrl: string, videoId: string} = {
    id: '',
    videoUrl: '',
    title: '',
    description: '',
    videoId: ''
  }

  setActiveVideo (video: any) {
    this.activeVideo = video
  }

  getBrandVideoTutorials (clear = true) {
    this.busy = true
    this.$store.dispatch('getBrandTutorialVideos', { query: this.query, limit: this.limit, offset: this.offset }).then((response) => {
      if (clear) this.brandTutorialVideos = []
      this.brandTutorialVideos.push(...response.response.body.brandTutorialVideos)
      this.totalCount = response.response.body.count
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }
  created () {
    this.getBrandVideoTutorials()
    this.$store.dispatch('getAppSettingsKeyValuePair')
  }
}
