var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-dialog-centered modal-lg" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h2", [_vm._v(_vm._s(_vm.video.title))])
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("iframe", {
                staticStyle: {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "600px"
                },
                attrs: {
                  src: "https://www.youtube.com/embed/" + _vm.video.videoId,
                  title: _vm.video.title,
                  frameborder: "2",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }
              })
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }